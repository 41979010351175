@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/formbase@12.0.0/dist/formbase.min.css');
body {
  font-family: Arial, sans-serif;
  color: #222222;
  margin: 0;
  font-size: 14px;
}

button {
  text-transform: uppercase;
  border-radius: 0;
  cursor: pointer;
}

img {
  max-height: 35px;
  margin-top: 10px;
  margin-bottom: 7px;
}

a {
  text-decoration: none;
  color: #2a9cd7;
}

/* Extra small screen / phone */

/* small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

/* Largest screen / wide desktop */

/* ITEM Stylesc */
/* ------------------------- */
/*0 2px 2px rgba(220,220,220,0.6);  */

/* Fonts */

@media screen and (max-width: 420px) {
    .basicVariables_weiterButton__Vc3M7 {
        width: 100%;
    }
}

.basicVariables_weiterButton__Vc3M7:hover {
    background: #83ab1a;
}

.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7.basicVariables_disabled__L5CEP {
    background: #c8c8c8;
    border: none;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
}

.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7 {
    outline: 0;
    display: block;
    cursor: pointer;
    font-family: 'Montserrat', 'Helvetica Neue', 'Arial', sans-serif;
    background: #95c100;
    font-size: 1em;
    padding: 10px 15px;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}

.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7:disabled {
    background: #ddd;
    border: none;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
}

@media screen and (max-width: 420px) {
    .basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7 {
        width: 100%;
    }
}

.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7:hover {
    background: #83ab1a;
}

.basicVariables_btn__nux0S.basicVariables_ablehnen__fkhV_ {
    background: #ff5353;
    margin-left: 5px;
}
.basicVariables_btn__nux0S.basicVariables_cancel__6NXVQ {
    background: #ff5353;
}
.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7.basicVariables_ablehnen__fkhV_:hover {
    background: #ff3131;
}

.basicVariables_main__U1GI4 {
    margin: 40px 0 25px 200px;
}

.basicVariables_weiterButton__Vc3M7.basicVariables_inlineButton__XyXwE {
    font-size: 0.85em;
    padding: 5px;
    display: inline-block;
    margin: 10px;
}

.basicVariables_main__U1GI4 .vorgangContent {
    min-height: 50vh;
}

.basicVariables_row__URsnr {
    display: flex;
}

.basicVariables_crudForm__wr_IK {
    width: 30%;
    padding: 25px;
}

.basicVariables_key__x3ywf {
    font-weight: 600;
    background: #f1f1f1;
    width: 45%;
}

.basicVariables_value__OOmfy {
    word-break: break-all;
}

.basicVariables_konditionenForm__CIYnK {
    margin-top: 15px;
}

.basicVariables_konditionenForm__CIYnK .basicVariables_invalidMessage__IU1eS {
    display: none;
    color: #ff5353;
    margin-bottom: 15px;
}

.basicVariables_textCenter__wupjK {
    text-align: center;
}

.basicVariables_sidebar__cDCwR {
    border-right: 1px solid #d6d6d6;
    background: #f0f0f0;
    height: 100vh;
    width: 200px;
    position: fixed;
    z-index: 2;
    top: 0;
}

.basicVariables_sidebar__cDCwR img {
    width: 150px;
    display: block;
    margin: 15px auto 0;
}

.basicVariables_sidebar__cDCwR ul {
    padding: 0;
    list-style: none;
    line-height: 2.5em;
    margin: 45px 25px;
}

.basicVariables_sidebar__cDCwR ul a {
    color: #222222;
}

.basicVariables_sidebar__cDCwR ul a.basicVariables_isActive__DDgZp {
    font-weight: bold;
}

.basicVariables_sidebar__cDCwR ul li {
    display: flex;
    align-items: center;
}

.basicVariables_sidebar__cDCwR .basicVariables_redDot__HcfId {
    background: #ff5353;
    height: 7px;
    width: 7px;
    border-radius: 10px;
    margin-left: 10px;
}

.basicVariables_navbar__JJqr_ {
    height: 40px;
    left: 200px;
    background: #f0f0f0;
    width: calc(100% - 200px);
    display: flex;
    top: 0;
    position: fixed;
    text-align: right;
    line-height: 40px;
    border-bottom: 1px solid #d6d6d6;
    z-index: 1;
    align-items: center;
}

.basicVariables_navbar__JJqr_ .basicVariables_leftColumn__LF97M,
.basicVariables_navbar__JJqr_ .basicVariables_rightColumn__i8oIl {
    flex: 1;
    padding: 0 10px;
}

.basicVariables_navbar__JJqr_ .basicVariables_leftColumn__LF97M.basicVariables_leftColumn__LF97M,
.basicVariables_navbar__JJqr_ .basicVariables_rightColumn__i8oIl.basicVariables_leftColumn__LF97M {
    display: flex;
}

.basicVariables_navbar__JJqr_ .basicVariables_leftColumn__LF97M input[type='checkbox'],
.basicVariables_navbar__JJqr_ .basicVariables_rightColumn__i8oIl input[type='checkbox'] {
    margin-left: 10px;
    margin-right: 6px;
}

.basicVariables_navbar__JJqr_ .basicVariables_leftColumn__LF97M input[type='text'],
.basicVariables_navbar__JJqr_ .basicVariables_rightColumn__i8oIl input[type='text'] {
    height: 35px;
}

.basicVariables_navbar__JJqr_ .basicVariables_leftColumn__LF97M .basicVariables_flexFirst__jefw5,
.basicVariables_navbar__JJqr_ .basicVariables_rightColumn__i8oIl .basicVariables_flexFirst__jefw5 {
    flex: 1;
}

.basicVariables_navbar__JJqr_ a {
    margin-left: 10px;
    display: inline-block;
}

.basicVariables_notification__7vqtD {
    width: 600px;
    position: fixed;
    background: #ffec7f;
    border: 1px solid #ffd700;
    padding: 10px 20px 10px 10px;
    box-shadow: 0 0 5px 1px #d4d4d4;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    top: 20px;
    z-index: 3;
}

.basicVariables_notification__7vqtD button {
    font-weight: 600;
    border: 1px solid #ffd700;
    position: absolute;
    right: 3px;
    top: 3px;
}

.basicVariables_loginForm___tcgl,
.basicVariables_changePasswordForm__L5Jal {
    margin: 10rem auto;
    width: 20rem;
}
.basicVariables_loginForm___tcgl .basicVariables_textError__h15Xx,
.basicVariables_changePasswordForm__L5Jal .basicVariables_textError__h15Xx {
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    background: #ffe4e4;
    color: #ff5353;
    font-size: 0.85em;
    padding-bottom: 15px;
    border-color: #ff5353;
    margin-bottom: 20px;
    margin-top: -20px;
}
.basicVariables_loginForm___tcgl img,
.basicVariables_changePasswordForm__L5Jal img {
    width: 200px;
    display: block;
    margin: 0 auto 40px;
}
.basicVariables_loginForm___tcgl > div,
.basicVariables_changePasswordForm__L5Jal > div {
    border: 1px solid #d6d6d6;
    padding: 15px 25px 20px;
}
.basicVariables_loginForm___tcgl > div label,
.basicVariables_changePasswordForm__L5Jal > div label {
    display: inline-block;
    color: #a0a0a0;
    font-size: 0.8em;
    margin-top: 5px;
}
.basicVariables_loginForm___tcgl > div button,
.basicVariables_changePasswordForm__L5Jal > div button {
    width: 100%;
    border: none;
    color: #ffffff;
    font-weight: 600;
    background: #95c100;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 1em;
    padding: 10px;
    outline: 0;
}
.basicVariables_loginForm___tcgl > div input,
.basicVariables_changePasswordForm__L5Jal > div input {
    border-radius: 0;
}

.basicVariables_error__QG_Kn {
    box-shadow: 0 0 0 4px #2a9cd7;
    border-color: transparent;
}
.basicVariables_errorMessage__pBpd4 {
    color: #f00;
    display: block;
}

input:not(.basicVariables_input__poT__)[type='text'],
input:not(.basicVariables_input__poT__)[type='number'],
input:not(.basicVariables_input__poT__)[type='password'],
input:not(.basicVariables_input__poT__)[type='date'] {
    font-size: 1em;
    padding: 10px;
    outline: 0;
    width: 100%;
    margin: 5px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    background: #fff;
    height: 38px;
}

form > .basicVariables_field__4RBo9 input[type='date'] {
    height: 39px;
}
form > .basicVariables_field__4RBo9 .basicVariables_control__T3gLO label {
    cursor: pointer;
}
form > .basicVariables_field__4RBo9 > label {
    padding: 5px;
    display: inline-block;
}
form > .basicVariables_field__4RBo9.basicVariables_invalid___0RfN > input {
    border-color: #ff5353;
}

.basicVariables_activityLog__CiSK4,
.basicVariables_similarCases__6R858 {
    background: #f0f0f0;
    padding: 0 10px;
    width: 50%;
    margin-top: 30px;
    padding: 30px;
}
.basicVariables_activityLog__CiSK4.basicVariables_activityLog__CiSK4,
.basicVariables_similarCases__6R858.basicVariables_activityLog__CiSK4 {
    margin-left: -30px;
}
.basicVariables_activityLog__CiSK4 h2,
.basicVariables_similarCases__6R858 h2 {
    margin: 0 0 30px;
}
.basicVariables_activityLog__CiSK4 .basicVariables_action-required__nQuxp,
.basicVariables_similarCases__6R858 .basicVariables_action-required__nQuxp {
    color: #f89406;
}
.basicVariables_activityLog__CiSK4 .basicVariables_no-action-required__yha_u,
.basicVariables_similarCases__6R858 .basicVariables_no-action-required__yha_u {
    color: #95c100;
}
.basicVariables_activityLog__CiSK4 .basicVariables_content__0FOrw,
.basicVariables_similarCases__6R858 .basicVariables_content__0FOrw {
    background: #fff;
    padding: 15px;
}
.basicVariables_activityLog__CiSK4 .basicVariables_content__0FOrw ul,
.basicVariables_similarCases__6R858 .basicVariables_content__0FOrw ul {
    list-style: none;
    padding: 0;
}
.basicVariables_activityLog__CiSK4 .basicVariables_content__0FOrw .basicVariables_timestamp__yrDya,
.basicVariables_similarCases__6R858 .basicVariables_content__0FOrw .basicVariables_timestamp__yrDya {
    color: #777;
    margin-right: 5px;
}
.basicVariables_activityLog__CiSK4 .basicVariables_content__0FOrw .basicVariables_user__4_4pR,
.basicVariables_similarCases__6R858 .basicVariables_content__0FOrw .basicVariables_user__4_4pR {
    margin-right: 10px;
    font-style: italic;
}
.basicVariables_activityLog__CiSK4 .basicVariables_content__0FOrw .basicVariables_action__XcXCZ,
.basicVariables_similarCases__6R858 .basicVariables_content__0FOrw .basicVariables_action__XcXCZ {
    font-weight: bold;
}

.basicVariables_documentViewer__nXD8o {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerHeader__9mnzz {
    background: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 120%;
    display: flex;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerHeader__9mnzz .basicVariables_fileType__tlScL {
    width: 100%;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerHeader__9mnzz .basicVariables_close__nGkjn {
    display: block;
    width: 20px;
    padding: 0 10px;
    color: #aaa;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerHeader__9mnzz .basicVariables_close__nGkjn:hover {
    color: #888;
    cursor: pointer;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerBody__9UcJR {
    flex: 1;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerBody__9UcJR iframe {
    display: block;
    margin: 0 auto;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr {
    background: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 120%;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #aaa;
    background: none;
    border: none;
    padding: 0;
    margin: 0 20px;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi .basicVariables_fas__Uurrf {
    position: relative;
    top: -5px;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi:focus {
    outline: none;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi:hover {
    color: #888;
    cursor: pointer;
}
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi:disabled,
.basicVariables_documentViewer__nXD8o .basicVariables_viewerFooter__fZsvr .basicVariables_navigateLink__WT1oi:disabled:hover {
    cursor: not-allowed;
    color: #ddd;
}

#basicVariables_calculation__MwnVl {
    margin-top: 75px;
    margin-left: 230px;
    margin-bottom: 0;
}
#basicVariables_calculation__MwnVl .basicVariables_anfrageHeader__DLTgn,
#basicVariables_calculation__MwnVl .basicVariables_activityLog__CiSK4 {
    margin-right: 0;
}
#basicVariables_calculation__MwnVl .basicVariables_tabNavigation__JFJY7 {
    margin-right: 29px;
}

.basicVariables_icon__d7LJ5 {
    width: 18px;
    height: 18px;
    display: inline-block;
    bottom: -4px;
    position: relative;
    margin-left: 3px;
}

.basicVariables_table__PmGg6 {
    width: 100%;
    background: #ffffff;
    margin-bottom: 25px;
}

.basicVariables_table__PmGg6,
#basicVariables_calculation__MwnVl {
    border-collapse: collapse;
}

.basicVariables_table__PmGg6 td,
.basicVariables_table__PmGg6 th {
    text-align: left;
    border: 1px solid #d6d6d6;
    padding: 10px;
    margin: 0;
}

.basicVariables_table__PmGg6 th {
    background: #f1f1f1;
}

.basicVariables_table__PmGg6 button.basicVariables_btn__nux0S.basicVariables_weiterButton__Vc3M7 {
    padding: 0;
    border-radius: 0;
    font-size: 0.9em;
    margin: 5px;
}

.basicVariables_textarea___hl_X {
    font-size: 1em;
    padding: 10px;
    outline: 0;
    width: 100%;
    margin: 5px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    background: #fff;
    height: 38px;
}

.basicVariables_konditionenListe__TcXtT {
    width: 600px;
}
  

.errorPage_displayTextStatuscode__zHHFy {
  color: #f7f7f7;
}
.errorPage_errorPage__djH_h {
  text-align: center;
  padding: 20px 50px;
}
.errorPage_backToStart__IJyUf {
  width: 280px;
  margin: 40px auto 40px auto;
}

