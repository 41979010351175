@value gray-lighter from '../../styles/basicVariables.module.css';

.displayTextStatuscode {
  color: gray-lighter;
}
.errorPage {
  text-align: center;
  padding: 20px 50px;
}
.backToStart {
  width: 280px;
  margin: 40px auto 40px auto;
}
