@value orange: #f89406;
@value footer-background: #263d4a;
@value footer-gray: #80807e;
@value ekomi-stars-color: #f3d010;
@value logo-color-primary: #1570b8;
@value logo-color-secondary: #ec6608;

@value ekomi-stars-width: 119px;
@value ekomi-stars-height: 25px;
@value green: #95c100;
@value white: #fff;
@value whiteLight: #fafafa;
@value gray: #bbb;
@value gray-light: #c8c8c8;
@value gray-lighter: #f7f7f7;
@value black: #000;
@value blueGrayLight: #4b6879;
@value text-color: #263d4a;
@value link-color: #309dd8;

@value brand-primary: #2a9cd7;
@value brand-secondary: #f89406;

@value border-color: #cacaca;

@value red: #ff5353;

@value cta-default: #95c100;
@value cta-hover: #83ab1a;
@value cta-shadow: #7ca11d;

/* Extra small screen / phone */
@value screen-xxs: 420px;

/* small screen / phone */
@value screen-xs: 590px;

/* Small screen / tablet */
@value screen-sm: 768px;

/* Medium screen / desktop */
@value screen-md: 1100px;

/* Large screen / wide desktop */
@value screen-lg: 1200px;

/* Largest screen / wide desktop */
@value screen-xl: 1300px;

/* ITEM Stylesc */
/* ------------------------- */
@value item-margin: 0.5em;
@value item-main-shadow: #ccc;
/*0 2px 2px rgba(220,220,220,0.6);  */

/* Fonts */
@value montserrat: 'Montserrat', 'Helvetica Neue', 'Arial', sans-serif;
@value roboto: 'Roboto', sans-serif;

@media screen and (max-width: 420px) {
    .weiterButton {
        width: 100%;
    }
}

.weiterButton:hover {
    background: #83ab1a;
}

.btn.weiterButton.disabled {
    background: #c8c8c8;
    border: none;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
}

.btn.weiterButton {
    outline: 0;
    display: block;
    cursor: pointer;
    font-family: 'Montserrat', 'Helvetica Neue', 'Arial', sans-serif;
    background: #95c100;
    font-size: 1em;
    padding: 10px 15px;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}

.btn.weiterButton:disabled {
    background: #ddd;
    border: none;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
}

@media screen and (max-width: 420px) {
    .btn.weiterButton {
        width: 100%;
    }
}

.btn.weiterButton:hover {
    background: #83ab1a;
}

.btn.ablehnen {
    background: #ff5353;
    margin-left: 5px;
}
.btn.cancel {
    background: #ff5353;
}
.btn.weiterButton.ablehnen:hover {
    background: #ff3131;
}

.main {
    margin: 40px 0 25px 200px;
}

.weiterButton.inlineButton {
    font-size: 0.85em;
    padding: 5px;
    display: inline-block;
    margin: 10px;
}

.main :global(.vorgangContent) {
    min-height: 50vh;
}

.row {
    display: flex;
}

.crudForm {
    width: 30%;
    padding: 25px;
}

.key {
    font-weight: 600;
    background: #f1f1f1;
    width: 45%;
}

.value {
    word-break: break-all;
}

.konditionenForm {
    margin-top: 15px;
}

.konditionenForm .invalidMessage {
    display: none;
    color: #ff5353;
    margin-bottom: 15px;
}

.textCenter {
    text-align: center;
}

.sidebar {
    border-right: 1px solid #d6d6d6;
    background: #f0f0f0;
    height: 100vh;
    width: 200px;
    position: fixed;
    z-index: 2;
    top: 0;
}

.sidebar img {
    width: 150px;
    display: block;
    margin: 15px auto 0;
}

.sidebar ul {
    padding: 0;
    list-style: none;
    line-height: 2.5em;
    margin: 45px 25px;
}

.sidebar ul a {
    color: #222222;
}

.sidebar ul a.isActive {
    font-weight: bold;
}

.sidebar ul li {
    display: flex;
    align-items: center;
}

.sidebar .redDot {
    background: #ff5353;
    height: 7px;
    width: 7px;
    border-radius: 10px;
    margin-left: 10px;
}

.navbar {
    height: 40px;
    left: 200px;
    background: #f0f0f0;
    width: calc(100% - 200px);
    display: flex;
    top: 0;
    position: fixed;
    text-align: right;
    line-height: 40px;
    border-bottom: 1px solid #d6d6d6;
    z-index: 1;
    align-items: center;
}

.navbar .leftColumn,
.navbar .rightColumn {
    flex: 1;
    padding: 0 10px;
}

.navbar .leftColumn.leftColumn,
.navbar .rightColumn.leftColumn {
    display: flex;
}

.navbar .leftColumn input[type='checkbox'],
.navbar .rightColumn input[type='checkbox'] {
    margin-left: 10px;
    margin-right: 6px;
}

.navbar .leftColumn input[type='text'],
.navbar .rightColumn input[type='text'] {
    height: 35px;
}

.navbar .leftColumn .flexFirst,
.navbar .rightColumn .flexFirst {
    flex: 1;
}

.navbar a {
    margin-left: 10px;
    display: inline-block;
}

.notification {
    width: 600px;
    position: fixed;
    background: #ffec7f;
    border: 1px solid #ffd700;
    padding: 10px 20px 10px 10px;
    box-shadow: 0 0 5px 1px #d4d4d4;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    top: 20px;
    z-index: 3;
}

.notification button {
    font-weight: 600;
    border: 1px solid #ffd700;
    position: absolute;
    right: 3px;
    top: 3px;
}

.loginForm,
.changePasswordForm {
    margin: 10rem auto;
    width: 20rem;
}
.loginForm .textError,
.changePasswordForm .textError {
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    background: #ffe4e4;
    color: #ff5353;
    font-size: 0.85em;
    padding-bottom: 15px;
    border-color: #ff5353;
    margin-bottom: 20px;
    margin-top: -20px;
}
.loginForm img,
.changePasswordForm img {
    width: 200px;
    display: block;
    margin: 0 auto 40px;
}
.loginForm > div,
.changePasswordForm > div {
    border: 1px solid #d6d6d6;
    padding: 15px 25px 20px;
}
.loginForm > div label,
.changePasswordForm > div label {
    display: inline-block;
    color: #a0a0a0;
    font-size: 0.8em;
    margin-top: 5px;
}
.loginForm > div button,
.changePasswordForm > div button {
    width: 100%;
    border: none;
    color: #ffffff;
    font-weight: 600;
    background: #95c100;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 1em;
    padding: 10px;
    outline: 0;
}
.loginForm > div input,
.changePasswordForm > div input {
    border-radius: 0;
}

.error {
    box-shadow: 0 0 0 4px #2a9cd7;
    border-color: transparent;
}
.errorMessage {
    color: #f00;
    display: block;
}

input:not(.input)[type='text'],
input:not(.input)[type='number'],
input:not(.input)[type='password'],
input:not(.input)[type='date'] {
    font-size: 1em;
    padding: 10px;
    outline: 0;
    width: 100%;
    margin: 5px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    background: #fff;
    height: 38px;
}

form > .field input[type='date'] {
    height: 39px;
}
form > .field .control label {
    cursor: pointer;
}
form > .field > label {
    padding: 5px;
    display: inline-block;
}
form > .field.invalid > input {
    border-color: #ff5353;
}

.activityLog,
.similarCases {
    background: #f0f0f0;
    padding: 0 10px;
    width: 50%;
    margin-top: 30px;
    padding: 30px;
}
.activityLog.activityLog,
.similarCases.activityLog {
    margin-left: -30px;
}
.activityLog h2,
.similarCases h2 {
    margin: 0 0 30px;
}
.activityLog .action-required,
.similarCases .action-required {
    color: #f89406;
}
.activityLog .no-action-required,
.similarCases .no-action-required {
    color: #95c100;
}
.activityLog .content,
.similarCases .content {
    background: #fff;
    padding: 15px;
}
.activityLog .content ul,
.similarCases .content ul {
    list-style: none;
    padding: 0;
}
.activityLog .content .timestamp,
.similarCases .content .timestamp {
    color: #777;
    margin-right: 5px;
}
.activityLog .content .user,
.similarCases .content .user {
    margin-right: 10px;
    font-style: italic;
}
.activityLog .content .action,
.similarCases .content .action {
    font-weight: bold;
}

.documentViewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
}
.documentViewer .viewerHeader {
    background: #fff;
    text-align: center;
    padding: 10px 0;
    font-size: 120%;
    display: flex;
}
.documentViewer .viewerHeader .fileType {
    width: 100%;
}
.documentViewer .viewerHeader .close {
    display: block;
    width: 20px;
    padding: 0 10px;
    color: #aaa;
}
.documentViewer .viewerHeader .close:hover {
    color: #888;
    cursor: pointer;
}
.documentViewer .viewerBody {
    flex: 1;
}
.documentViewer .viewerBody iframe {
    display: block;
    margin: 0 auto;
}
.documentViewer .viewerFooter {
    background: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 120%;
}
.documentViewer .viewerFooter .navigateLink {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #aaa;
    background: none;
    border: none;
    padding: 0;
    margin: 0 20px;
}
.documentViewer .viewerFooter .navigateLink .fas {
    position: relative;
    top: -5px;
}
.documentViewer .viewerFooter .navigateLink:focus {
    outline: none;
}
.documentViewer .viewerFooter .navigateLink:hover {
    color: #888;
    cursor: pointer;
}
.documentViewer .viewerFooter .navigateLink:disabled,
.documentViewer .viewerFooter .navigateLink:disabled:hover {
    cursor: not-allowed;
    color: #ddd;
}

#calculation {
    margin-top: 75px;
    margin-left: 230px;
    margin-bottom: 0;
}
#calculation .anfrageHeader,
#calculation .activityLog {
    margin-right: 0;
}
#calculation .tabNavigation {
    margin-right: 29px;
}

.icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    bottom: -4px;
    position: relative;
    margin-left: 3px;
}

.table {
    width: 100%;
    background: #ffffff;
    margin-bottom: 25px;
}

.table,
#calculation {
    border-collapse: collapse;
}

.table td,
.table th {
    text-align: left;
    border: 1px solid #d6d6d6;
    padding: 10px;
    margin: 0;
}

.table th {
    background: #f1f1f1;
}

.table button.btn.weiterButton {
    padding: 0;
    border-radius: 0;
    font-size: 0.9em;
    margin: 5px;
}

.textarea {
    font-size: 1em;
    padding: 10px;
    outline: 0;
    width: 100%;
    margin: 5px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    background: #fff;
    height: 38px;
}

.konditionenListe {
    width: 600px;
}
  
